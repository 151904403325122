import React from "react"
import NoticeBoard from "./post-notice"
import { Container, Carousel, Row } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Marquee from "./marquee"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => (
  <React.Fragment>
    <Layout pageInfo={{ pageName: "Home" }}>
      <SEO title="Home" keywords={[`education`, `doedbnmu`, `bnmu`, `madhepura`]} />
      <Container>
        <Carousel>
        <Carousel.Item>
            <StaticImage
              formats={["auto", "webp"]}
              src="../../static/images/gallery/IMG-20240720-WA0008.jpg"
              alt="#"
              placeholder="traced_svg"
            />
          </Carousel.Item>
        <Carousel.Item>
            <StaticImage
              formats={["auto", "webp"]}
              src="../../static/images/gallery/001100.jpeg"
              alt="#"
              placeholder="traced_svg"
            />
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              formats={["auto", "webp"]}
              src="../../static/images/gallery/IMG-20190709-WA0106.jpg"
              alt="#"
              placeholder="traced_svg"
            />
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              placeholder="traced_svg"
              src="../../static/images/gallery/IMG-20190709-WA0110.jpg"
              formats={["auto", "webp"]}
              alt="#"
            />
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              formats={["auto", "webp"]}
              src="../../static/images/gallery/IMG-20190709-WA0110.jpg"
              alt="#"
              placeholder="traced_svg"
            />
          </Carousel.Item>
        </Carousel>
      </Container>
      <br />
    <NoticeBoard />
  </Layout>
  </React.Fragment>
)
export default IndexPage
