import React from "react"

const Marquee = () => (
  <>
  <h4>
   /* <marquee><a></a></marquee> */
  </h4>
  </>
)
export default Marquee
